import { isNil } from "ramda";
import { EventName, parseEvent, postEvent } from "./events";
import {
  CommonOptions,
  PayonusGateway as PayonusGatewayInterface,
  PayonusGatewayHandler,
} from "./types";
import { createIframe, validateOptions } from "./utils";

export const PayonusGateway: PayonusGatewayInterface = {
  create(options: CommonOptions): PayonusGatewayHandler {

    options = validateOptions(options);
    let url = '';
    if (options.environment === 'live') {
      url = 'https://pay.payonus.com'
    } else if (options.environment === 'test') {
      url = 'https://uatpay.payonus.com'
    } else {
      url = 'http://127.0.0.1:5177' // Update this during local test to whatever you want
    }

    const iframeID = `payonus-gateway-ifram-id-${Math.random().toString(7)}`;

    const params: { mode: string } = {
      mode: "iframe",
    };

    const cleanup = (handler: (e: MessageEvent) => void) => {
      document.getElementById(iframeID)!.remove();
      window.removeEventListener("message", handler);
    };

    const eventHandler = (e: MessageEvent) => {
      const event = parseEvent({
        event: e,
        expectedOrigin: "*",
      });

      if (isNil(event)) {
        return;
      }

      const { onFailure, onSuccess, onPending, ...rest } = options;

      switch (event.name) {
        case EventName.AUTH_REQUEST:
          postEvent({
            target: (document.getElementById(iframeID) as HTMLIFrameElement)
              .contentWindow as Window,
            event: {
              name: EventName.AUTH_CONFIRM,
              payload: {
                origin: window.location.origin,
                options: rest,
              },
            },
          });
          return;
        case EventName.RETURN:
          if (!isNil(onSuccess)) {
            onSuccess(event.payload);
          }
          cleanup(eventHandler);
          return;
        case EventName.EXIT:
          if (!isNil(onFailure)) {
            onFailure(event.payload, {});
          }
          cleanup(eventHandler);
          return;
        default:
          break;
      }
    };

    const openPopup = () => {
      window.addEventListener("message", eventHandler);
      const query = new URLSearchParams(params).toString();
      document.body.appendChild(
        createIframe(iframeID, `${url}?${query}`)
        // createIframe(iframeID, `http://localhost:5173?${query}`)
      );
    };

    const exitPopup = () => {
      cleanup(eventHandler);
    };

    return {
      openPopup,
      exitPopup,
    };
  },
};

declare global {
  interface PayonusGatewayWindow extends Window {
    PayonusGateway: typeof PayonusGateway;
  }
}

declare var window: PayonusGatewayWindow;

window.PayonusGateway = PayonusGateway;

export default PayonusGateway;
