export type Event = {
  name: string;
  payload: {
    origin?: string;
    options?: any;
    message?: string;
    code?: string;
    payload?: any;
  };
};

export enum EventName {
  // PayonusGateway will ask parent to authenticate:
  AUTH_REQUEST = "payonus:auth_request",
  // Dropin client will respond with a request to confirm the origin:
  AUTH_CONFIRM = "payonus:auth_confirm",
  // Sent after authentication to show, indicating the dropin has started:
  INIT = "payonus:init",
  // Sent after the flow completes without error:
  RETURN = "payonus:return",
  // Sent when the flow is requesting to exit:
  EXIT = "payonus:exit",

  KORAPAY_REDIRECT_EVENT_NAME = "KORAPAY_REDIRECT_EVENT_NAME",
}

export const postEvent = ({
  event,
  target,
  targetOrigin = "*",
}: {
  event: Event;
  target: Window;
  targetOrigin?: string;
}) => {
  return target.postMessage(event, targetOrigin);
};

export const parseEvent = ({
  event,
  expectedOrigin = "*",
}: {
  event: MessageEvent;
  expectedOrigin: string;
}): Event | undefined => {
  if (expectedOrigin !== "*") {
    if (event.origin !== expectedOrigin) {
      console.debug(
        `event origin mismatch: event.origin ${event.origin} expectedOrigin ${expectedOrigin}`
      );
      return;
    }
  }

  // todo: look out for edge cases
  if (!Object.values(EventName).includes(event.data?.name)) {
    return;
  }

  return event.data as Event;
};

// wait to recieve a particular event type from any origin
export const waitEvent = ({
  eventName,
  expectedOrigin,
}: {
  eventName: EventName;
  expectedOrigin: string;
}): Promise<Event> => {
  return new Promise((resolve) => {
    window.addEventListener("message", (messageEvent: MessageEvent) => {
      const event = parseEvent({ event: messageEvent, expectedOrigin });
      if (event && event.name === eventName) resolve(event);
    });
  });
};
