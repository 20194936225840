import { isNil, gte } from "ramda";
import { RequiredErrorMessages } from "./errors";

import { CommonOptions } from "./types";
import { validateEmail, validateTransactionRef } from "./validations";

export const validateOptions = (options: CommonOptions): CommonOptions => {
  const { clientId, amount, name, transactionRef, email } = options;

  if (isNil(clientId)) {
    throw new Error(RequiredErrorMessages.secretKey);
  }
  if (isNil(amount) || !gte(amount, 1)) {
    throw new Error(RequiredErrorMessages.amount);
  }
  if (isNil(name)) {
    throw new Error(RequiredErrorMessages.name);
  }
  if (isNil(transactionRef) || !validateTransactionRef(transactionRef)) {
    throw new Error(RequiredErrorMessages.transactionRef);
  }
  if (isNil(email) || !validateEmail(email)) {
    throw new Error(RequiredErrorMessages.transactionRef);
  }

  return options;
};

export const createIframe = (elementId: string, url: string) => {
  const iframe = document.createElement("iframe");

  iframe.setAttribute("src", url);
  iframe.setAttribute("id", elementId);
  iframe.setAttribute("name", elementId);
  iframe.setAttribute("height", "100%");
  iframe.setAttribute("width", "100%");
  iframe.setAttribute("allowtransparency", "true");
  iframe.setAttribute("frame-src", "self");
  iframe.setAttribute("allow", "clipboard-read; clipboard-write");
  iframe.setAttribute(
    "style",

    `
          position: fixed;
          inset: 0px;
          z-index: 2147483647;
          border-width: 0px;
          display: flex;
          overflow: hidden auto;
          max-width: 100%;
          border: 0;
          background: rgba(0, 0, 0, 0.75);
          `
  );

  return iframe;
};
